import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import z from "zod";

export function isValidBody<T extends z.ZodTypeAny>(
  body: any,
  schema: T,
): body is z.infer<typeof schema> {
  const { success } = schema.safeParse(body);
  return success;
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const validHexChars = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
];

export function isValidHexaCode(str: string) {
  if (str[0] != "#") return false;

  if (!(str.length == 4 || str.length == 7)) return false;

  for (let i = 1; i < str.length; i++)
    if (validHexChars.indexOf(str[i]) == -1) return false;

  return true;
}
